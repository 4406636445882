img {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button-round {
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 20%;
    height: 20%;
}

button:active {
    outline: none;
    border: none;
}

button:focus {
    outline: 0;
}

.currentPlayerThrow {
    border-radius: 20px;
    padding: 15px;
    margin-top: 20px;
}

.currentPlayerBackground {
    background-color: #8abdda;
}

.otherPlayersBackground {
    background-color: #cccccc;
}

.throw {
    border-spacing: 5px 0;
    float: right;
}

.throw td {
    background-color: #d3d3d3;
    font-size: 3em;
    font-weight: bold;
    width: 60px;
    height: 60px;
    text-align: center;
}

.overthrow {
    width: 326px;
    height: 66px;
    color: #ff0000;
    font-weight: bold;
    font-size: 2em;
}

.statistic {
    border-spacing: 5px 0;
}

.statistic td {
    font-size: 1.5em;
    min-width: 150px;
    text-align: left;
}

.center800 {
    max-width: 800px;
    min-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.startLeft {
    max-width: 500px;
    float: left;
    margin-top: 10px;
    min-width: 250px;
    margin-right: 100px;
}

.start-form-select {
    width: 170px;
    font-size: 18px;
    line-height: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
}


.playerNameInput {
    margin-left: 20px;
    margin-right: 20px;
    width: 150px;
    height: 40px;
    font-size: 18px;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    border: 1px solid #000000;
    box-shadow: none;
    border-radius: 5px;
}

.playerNameInput:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border-radius: 5px;
    border: 1px solid rgba(81, 203, 238, 1);
}

.startRight {
    float: left;
    max-width: 400px;
    min-width: 250px;
    margin-top: 60px;
    font-size: 18px;
    font-weight: bold;
}

.startInputCell {
    line-height: 40px;
    height: 40px;
    display: table-cell;
    vertical-align: middle;
}

.resultWrapper {
    margin-top: 10px;
    margin-bottom: 60px;
    width: 400px;
    float: left;
    font-size: 1.2em;
}

.actionButton {
    text-align: center;
    cursor: pointer;
    font-size: 1.5em;
    padding: 20px;
    width: 300px;
    margin-top: 20px;
    background-color: #8abdda;
}


.floatLeft {
    float: left;
  }
  
  
  .floatRight {
      float: right;
    }
  
  .quitGameButton {
      text-align: center;
      cursor: pointer;
      font-size: 1em;
      padding: 10px;
      margin-top: 40px;
      background-color: #8abdda;
      width: 150px;
      margin-top: 40px;
      margin-right: 60px;
  }

.arrow {
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    cursor: pointer;
    width: 30px;
    height: 40px;
    float: left;
    margin-top: 20px;
}

.arrow:hover {
    background-color: gray;
}

.welcomeText {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 40px
}

.playerInput, .playerName {
    float: left;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
    vertical-align: middle;
}

.clear {
    display: none;
}

.iconSelect {
    display: block;
    float: left;
    margin-top: 20px;
    width: 150px
}

.iconSelectSmall {
    display: none;
}

.imageAndName {
    float: left;
    overflow: hidden;
    white-space: nowrap;
    width: 50%;
    font-size: 2em;
}

.playerIcon {
    height: auto;
    width: 40px;
    padding-right: 20px
}

.numbersWrapper {
    margin-top: 20px;
    float: left;
    min-width: 300px;
    width: 50%
}

.playersWrapper {
    float: left;
    width: 45%;
    min-width: 300px;
}

.infoStatSmall {
    display: none;
}

.finishWaySmall {
    display: none;
}

.infoStatBig {
    display: block;
    font-size: 1.5em;

}


.holePlayWrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
}


/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
}




@media screen and (min-width: 1100px) {
    .playersWrapper {
        padding-left: 5%;
        padding-right: 0;
    }
}

@media screen and (min-width: 800px) {
    .playersWrapper {
        padding-left: 4%;
        padding-right: 1%;
    }
}


@media screen and (max-width: 799px) {

    .actionButton {
        margin-left: 20px;
    }

    .welcomeText {
        margin-left: 20px;
    }

    .startLeft {
        margin-right: 40px;
        margin-left: 20px;
    }

    .startRight {
        margin-left: 20px;
    }


    .currentPlayerThrow {
        padding: 5px;
    }

    .infoStatSmall {
        display: inline-block;
    }

    .finishWaySmall {
        display: block;
    }

    .infoStatBig {
        display: none;
    }

    /*play*/
    .throw td {
        background-color: #d3d3d3;
        font-size: 1.5em;
        font-weight: bold;
        width: 30px;
        height: 30px;
        text-align: center;
    }

    .playerIcon {
        height: auto;
        width: 20px;
        padding-right: 5px
    }

    .imageAndName {
        font-size: 1em;
    }

    .overthrow {
        float: right;
        width: 150px;
        height: 30px;
        color: #ff0000;
        font-weight: bold;
        font-size: 1.5em;
    }

    .holePlayWrapper {
        max-width: 400px
    }

    .numbersWrapper {
        width: 100%;
    }

    .playersWrapper {
        width: 100%;
    }
}


@media screen and (max-width: 530px) {


   

    .welcomeText {
        margin-left: 20px;
    }

    .clear {
        display: block;
        clear: both;
    }

    /*start-page*/
    .playerInput {
        float: none;
        margin-top: 0px;
        margin-left: 20px;
        line-height: 30px;
    }

    .playerName {
        margin-top: 0px;
        margin-left: 20px;
        line-height: 30px;
    }

    .playerNameInput {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 2px;
        margin-bottom: 10px;
        width: 210px;
    }

    .iconSelect {
        display: none;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .iconSelectSmall {
        display: block;
        float: left;
        margin-left: 60px;
    }
}
